import React from "react";
import "../styles/ServiceBox.css";

const ServiceBox = ({ title, description, image }) => {
  return (
    <div className="service-box">
      <img src={image} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default ServiceBox;
