import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Footer3 from "../components/Footer3"; // Updated to Footer3

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <Footer3 /> {/* Updated to Footer3 */}
    </div>
  );
};

export default HomePage;
