import React, { useEffect, useState } from 'react';
import ServiceBox from './ServiceBox';
import { fetchPosts } from '../services/api';
import '../styles/Services.css';

const Services = () => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        fetchPosts().then(data => {
            console.log('Fetched data:', data); // Log the data to inspect it
            if (Array.isArray(data) && data.length > 0) {
                setServices(data); // Only set if data is a non-empty array
            } else {
                console.error('Expected an array of services but received:', data);
                setServices([]); // Ensure services is set to an empty array if data is invalid
            }
        }).catch(error => {
            console.error('Error fetching services:', error);
            setServices([]); // Set to empty array on error to avoid .map issues
        });
    }, []);

    return (
        <section className="services">
            <h2>Our Services</h2>
            <div className="services-container">
                {services.length > 0 ? (
                    services.map((service) => (
                        <ServiceBox
                            key={service.id}
                            title={service.title?.rendered || 'Untitled'}
                            description={service.excerpt?.rendered || 'No description available'}
                            image={service.featured_media_url || 'https://via.placeholder.com/150'}
                        />
                    ))
                ) : (
                    <p>Loading services...</p>
                )}
            </div>
        </section>
    );
};

export default Services;
