import React, { useEffect, useState } from 'react';
import { fetchPageContent } from '../services/api';
import '../styles/Hero.css';

const Hero = () => {
    const [heroContent, setHeroContent] = useState({ title: 'Welcome to My Website', description: 'No description available' });

    useEffect(() => {
        // Use the actual Page ID for the Hero section page
        fetchPageContent(39).then((data) => {
            console.log('Fetched Hero content:', data); // Log to inspect data
            if (data && data.title && data.content) {
                setHeroContent({
                    title: data.title.rendered || 'Welcome to My Website',
                    description: data.content.rendered || 'No description available'
                });
            } else {
                console.error('Incomplete data for Hero content:', data);
            }
        }).catch(error => {
            console.error('Error fetching Hero content:', error);
        });
    }, []);

    return (
        <section className="hero">
            <h1>{heroContent.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: heroContent.description }}></div>
            <button>Contact Us</button>
        </section>
    );
};

export default Hero;
