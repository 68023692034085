import React from "react";
import "../styles/Footer3.css";

const Footer3 = () => {
  return (
    <footer className="footer">
      <p>&copy; 2023 My Website. All rights reserved.</p>
    </footer>
  );
};

export default Footer3;
