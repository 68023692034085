import React from "react";
import HomePage from "./pages/HomePage.js";
import "./App.css";
import "./styles.css";

export default function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}
