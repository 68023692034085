import axios from "axios";

// Base URL for the WordPress REST API
const API_BASE_URL = "https://kodbir.com/wp-json/wp/v2";

// Function to fetch page content (e.g., for the Hero section)
export const fetchPageContent = async (pageId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/pages/${pageId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching page content:", error);
    return null;
  }
};

// Function to fetch posts (e.g., for the Services section)
export const fetchPosts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching posts:", error);
    return [];
  }
};
